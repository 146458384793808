import React, { useState } from "react";
import { CombinedRefRadioButton } from "./index";
import iconCheckmark from "../../../assets/images/icon_checkmark.svg";

const InputRadio = ({
  title,
  register,
  name,
  errors,
  value,
  newValue,
  customOnChange,
  substringToStyle,
  styleSubstring,
}) => {
  const [showChecked, setShowChecked] = useState(false);
  return (
    <>
      <CombinedRefRadioButton
        {...register}
        customOnChange={customOnChange}
        name={name}
        value={value}
        newValue={newValue}
        setShowChecked={setShowChecked}
      >
        <div className="row text-left mx-0 align-items-center mb-3">
          <div className="checkbox-img-col col-1-lg px-0">
            <span
              className={`checkbox-img-container ${showChecked && "checked"}`}
            >
              <img src={iconCheckmark}></img>
            </span>
          </div>
          <div className="checkbox-label-col col col-11-lg pl-3 pr-0">
            <div className="input-checkbox-label">
              {styleSubstring ? styleSubstring(title, substringToStyle) : title}
            </div>
          </div>
        </div>
      </CombinedRefRadioButton>
      {errors && errors[name] && (
        <div className="input-error">{errors[name].message}</div>
      )}
    </>
  );
};

export default InputRadio;
