import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useCombinedRefs } from "../../../utils/hooks";
import calendarIcon from "../../../assets/images/icon_calendar.svg";
import { DatePicker } from "@material-ui/pickers";
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

const materialTheme = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        borderColor: "black",
        "&::before": {
          borderColor: "black",
        },
        "&::after": {
          borderColor: "black",
        },
        "&:hover:before": {
          borderColor: "black !important",
        },
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#ad33a1",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#ad33a1",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: "rgba(173, 51, 161, 0.7)",
      },
      daySelected: {
        backgroundColor: "rgba(173, 51, 161, 1)",
        "&:hover": {
          backgroundColor: "rgba(173, 51, 161, 0.8)",
        },
      },
      dayDisabled: {
        color: "rgba(173, 51, 161, 0.2)",
      },
      current: {
        color: "rgba(173, 51, 161, 1.2)",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "rgba(173, 51, 161, 1)",
      },
    },
  },
});

const CombinedRefCustomDatePicker = forwardRef(
  (
    {
      customOnChange,
      onChange,
      onBlur,
      name,
      value,
      newValue,
      format,
      defaultValue,
    },
    forwardedRef
  ) => {
    const innerRef = useRef(null);
    const combinedRef = useCombinedRefs(forwardedRef, innerRef);
    const [open, setOpen] = useState(false);
    const [innerValue, setInnerValue] = useState(null);

    const onClose = () => {
      setOpen(false);
    };

    const onDateChange = (date) => {
      setOpen(false);
      setInnerValue(date);
      combinedRef.current.value = date
        ? date.format(format ? format : "DD-MM-YYYY")
        : "";
      let target = { target: combinedRef.current };
      onChange(target);
    };

    return (
      <div
        style={{ position: "relative" }}
        onClick={(e) => {
          if (e.target.tagName.toUpperCase() === "INPUT") setOpen(true);
        }}
      >
        <ThemeProvider theme={materialTheme}>
          <DatePicker
            name={name}
            placeholder={format ? format : "DD-MM-YYYY"}
            disablePast={true}
            format={format ? format : "DD-MM-YYYY"}
            onClose={onClose}
            open={open}
            style={{ width: "100%" }}
            value={innerValue}
            onChange={onDateChange}
            inputRef={combinedRef}
          />
        </ThemeProvider>
        {/* <div
          className="btn-icon"
          onClick={() => {
            setOpen(true);
          }}
          style={{ height: "100%", position: "absolute", right: 0 }}
        >
          <img
            style={{ height: "100%", maxHeight: "35px", width: "35px" }}
            src={calendarIcon}
          ></img>
        </div> */}
      </div>
    );
  }
);

export default CombinedRefCustomDatePicker;
