import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import api from '../../api'

import { SpinnerDotted } from 'spinners-react';
import { useWindowDimensions } from '../../utils/hooks';

import "./Home.css"

// Import Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
// Use require as workaround to resolve pagination css
// https://github.com/nolimits4web/swiper/issues/3777
require("swiper/components/pagination/pagination.min.css");
import "../Swiper/Swiper.css";

// import Swiper core and required modules
import SwiperCore, {
    Pagination
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination]);

import ethIcon from "../../assets/images/home/eth-ic.svg"
import carouselImg from "../../assets/images/home/image_carousel.png"
import customTraitsImg from "../../assets/images/home/image_custom-traits.png"
import easyToLaunchImg from "../../assets/images/home/image_easy-to-launch.png"
import whyEcImg from "../../assets/images/home/image_why-ethercards.png"
import logo1 from "../../assets/images/home/logo_01.png"
import logo2 from "../../assets/images/home/logo_02.png"
import logo3 from "../../assets/images/home/logo_03.png"
import logo4 from "../../assets/images/home/logo_04.png"
import logo5 from "../../assets/images/home/logo_05.png"
import superchiefLogo from "../../assets/images/home/superchief-logo.png"
import lameloLogo from "../../assets/images/home/sale-box_lamelo-ball.jpg"

const Home = () => {

    const [blogPosts, setBlogPosts] = useState([]);
    const [loadingNews, setLoadingNews] = useState(false);
    const { width, height } = useWindowDimensions();
    let lgBreakpoint = width >= 992;
    let smBreakpoint = width <= 576;

    useEffect(() => {
        fetchBlogPosts()
    }, [])

    const fetchBlogPosts = () => {
        setLoadingNews(true);
        const params = {
            key: "dfcef1fe8427de771b79ee43a3",
            limit: "4",
            fullUrl: true
        }
        api.get("https://ethercards.ghost.io/ghost/api/v3/content/posts/", params, true).then(res => {
            if (res) {
                setBlogPosts(res.posts)
            }
            setLoadingNews(false);
        })
    }

    return (
        <div id="home" className="container mt-2">
            {/* Carousel Header */}
            <section id="sectionCarousel">
                <Swiper
                    // pagination={{ clickable: true }} 
                    // loop={true} 
                    className="mySwiper">
                    <SwiperSlide>
                        <div className="carousel-content row">
                            {/* <div className="col-0 col-md-1"></div> */}
                            <div className="
                                order-2 order-sm-1
                                col-12 col-sm-6 col-md-5
                                offset-md-1
                                col-lg-4
                                offset-lg-2
                                align-self-center 
                                text-sm-right 
                                text-center">
                                <div className="title">
                                    Launch Dynamic NFT Cards
                                </div>
                                <div className="desc my-4">
                                    Easily create custom cards for your community or add traits
                                    to your existing card
                                </div>
                                <NavLink className="btn btn-outline btn-white" to="/form" exact>LAUNCH NOW</NavLink>
                            </div>
                            <div className="order-1 order-md-2 col-12 col-sm-6 col-md-5">
                                <div className="carousel-img-container">
                                    <img src={carouselImg}></img>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </section>

            {/* Content Text */}
            {/* <div className="row mt-5">
                <div className="col col-md-8 offset-md-2">
                    <div className="title">
                        Launch Dynamic NFTs
                    </div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                    </p>
                </div>
            </div> */}

            {/* Partner Sales */}
            <section id="sectionPartnerSales">
                <div className="row mt-5">
                    <div className="col-12 col-md-8 offset-md-2">
                        <div className="title">
                            Partner Sales
                        </div>
                        <p>
                            We make it easy to both customize and launch a collection. Sales and gamification can be
                            natively integrated on partner platforms.
                        </p>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6">
                        <div className="card-container">
                            <div className="sale-card-header">
                                <img src={lameloLogo}></img>
                            </div>
                            <div className="text-left">
                                <div className="sale-card-property">Number of Cards: <span>{'10,000'}</span></div>
                                <div className="sale-card-property">Discount: <span>{'2.5%-10%'}</span></div>
                                <p className="sale-card-desc">
                                    The world’s first dynamic sports NFTs, connecting fans to the NBA star rookie's career.
                                    Share in LaMelo's success with evolving benefits. Exclusive discounts for the Ether Cards community.
                                </p>
                            </div>
                            <div className="sale-card-bottom row justify-content-between mx-0">
                                <div className="sale-price">
                                    <img src={ethIcon} height={20}></img> {"0.01"}
                                </div>
                                <a className="btn btn-outline btn-action" href="https://lameloball.io" target="_blank">BUY</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="card-container">
                            <div className="sale-card-header">
                                <img style={{ height: '80%', width: '80%', objectFit: 'contain' }} src={superchiefLogo}></img>
                            </div>
                            <div className="text-left">
                                <div className="sale-card-property">Number of Cards: <span>{'TBA'}</span></div>
                                <div className="sale-card-property">Discount: <span>{'TBA'}</span></div>
                                <p className="sale-card-desc">
                                    Ether Cards is partnering with Superchief Gallery, the world’s 1st dedicated physical space for NFTs, based in SoHo NYC.
                                    Together we are trailblazing community participation in the art industry with custom NFT Member Cards.
                                </p>
                            </div>
                            <div className="row justify-content-between mx-0">
                                <div className="sale-price">
                                    <img src={ethIcon} height={20}></img> {"TBA"}
                                </div>
                                <a className="btn btn-outline btn-action disabled">COMING SOON</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Content Image */}
            <section id="sectionContent">
                <div className="row content-img">
                    <div className="col-12 col-sm-5 h-sm-100 text-center text-sm-right">
                        <div className="title">
                            Easy to Launch
                        </div>
                        <p>
                            Use our contact form to tell us about your project and we'll
                            create powerful, customized traits and gamifications for
                            your community
                        </p>
                    </div>
                    <div className="col-0 col-sm-1"></div>
                    <div className="col-12 col-sm-5 h-sm-100">
                        <img src={easyToLaunchImg}>
                        </img>
                    </div>
                    <div className="col-0 col-sm-1"></div>
                </div>

                <div className="row flex-row-reverse content-img">
                    <div className="col-12 col-sm-5 text-center text-sm-left h-sm-100">
                        <div className="title">
                            Custom Trait
                        </div>
                        <p>
                            Create traits that fit the unique qualities of your community,
                            choose from our templates or request a custom trait
                        </p>
                    </div>
                    <div className="col-0 col-sm-1"></div>
                    <div className="col-12 col-sm-5 h-sm-100">
                        <img src={customTraitsImg}>
                        </img>
                    </div>
                    <div className="col-0 col-sm-1"></div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-5 text-center text-sm-right h-sm-100">
                        <div className="title">
                            Seamless Integration
                        </div>
                        <p>
                            Launch on our site, or integrate our sales tools and
                            gamifications on your platform
                        </p>
                    </div>
                    <div className="col-0 col-sm-2"></div>
                    <div className="col-12 col-sm-5 text-center text-sm-left h-100">
                        <div className="title">
                            Keep Adding Value
                        </div>
                        <p>
                            Earn automated revenue with engaging dynamic features and gamified interface
                        </p>
                    </div>
                </div>
            </section>

            {/* Why EC */}
            <section id="sectionWhy">
                <div className="row mb-5">
                    <div className="col-12">
                        <div className="title text-center">
                            Why Ether Cards?
                        </div>
                    </div>
                    <div className="col-12 d-md-none">
                        <img src={whyEcImg} style={{ marginRight: '50px' }}></img>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-12 col-sm-6 col-md-3 d-flex flex-column text-center text-sm-right">
                        <div className="why-content">
                            <div className="title">
                                All in One
                        </div>
                            <p>
                                Ether Cards combine the functionality of subscription services, loyalty programs, collectibles and access passes.
                            </p>
                        </div>

                        <div className="why-content">
                            <div className="title">
                                Dynamic Bootstrapping
                        </div>
                            <p>
                                Supporters receive a cryptoasset whose value is directly correlated to the traction of your project.
                            </p>
                        </div>
                    </div>

                    <div className="col-4 d-md-block why-image">
                        <img src={whyEcImg}></img>
                    </div>

                    <div className="col-12 col-sm-6 col-md-3 d-flex flex-column text-center text-sm-left">
                        <div className="why-content">
                            <div className="title">
                                Powerful Visibility
                            </div>
                            <p>
                                Give your sale the ability to tap into the EtherCards community and branch out into our partner network and access passes.
                            </p>
                        </div>
                        <div className="why-content">
                            <div className="title">
                                Value-add Partners
                            </div>
                            <p>
                                Tokenized benefits are exchangeable stores of value that turn your community into value-add partners in your project’s growth.
                            </p>
                        </div>
                    </div>
                    <div className="col-1"></div>
                </div>
                <NavLink className="btn btn-outline" to="/form" exact>CREATE</NavLink>
            </section>


            {/* News */}
            <section id="sectionNews" className="my-5">
                <div className="title text-center">
                    Latest news
                </div>

                <Swiper className="news-swiper mb-5 pb-5" pagination={{ clickable: true }} slidesPerView={smBreakpoint ? 1 : lgBreakpoint ? 4 : 2} spaceBetween={30}>
                    <SpinnerDotted className="new-loader" enabled={loadingNews} size={35} thickness={160} speed={200} color="black" />
                    {blogPosts && blogPosts.length > 0 && blogPosts.map((post, index) => {
                        return (
                            <SwiperSlide key={`swipe_index_${index}`}>
                                <div className="card-container">
                                    <div className="news-header">
                                        <img src={post.feature_image}></img>
                                    </div>
                                    <div className="news-title">
                                        {post.title}
                                    </div>
                                    <div className="news-body">
                                        <p className="sale-card-desc">
                                            {post.excerpt}
                                        </p>
                                    </div>
                                    <a className="btn btn-outline btn-action" href={post.url} target="_blank">MORE</a>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>

                <a className="btn btn-outline btn-action" href={"https://blog.ether.cards"} target="_blank">SEE ALL</a>
            </section>

            {/* Partner & Clients Carousel */}
            <section id="sectionPartner" className="my-5">
                <div className="title text-center">
                    Partners & Clients
                </div>

                <Swiper className="partner-swiper my-5" pagination={{ clickable: true }} slidesPerView={lgBreakpoint ? 5 : 3} spaceBetween={20}>
                    <SwiperSlide>
                        <a href="https://twitter.com/ethereum" target="_blank">
                            <img src={logo1}></img>
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://twitter.com/Consensys" target="_blank">
                            <img src={logo2}></img>
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://twitter.com/chainlink" target="_blank">
                            <img src={logo3}></img>
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://twitter.com/brave" target="_blank">
                            <img src={logo4}></img>
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://twitter.com/SuperchiefNFT" target="_blank">
                            <img src={logo5}></img>
                        </a>
                    </SwiperSlide>
                </Swiper>
            </section>
        </div>
    )
}

export default Home;