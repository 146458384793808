import React from 'react';
import './FooterComponent.css';
import logo from '../../assets/images/logo-in-menu.png';

const FooterComponent = () => {

  return (
    <div id="footer">
      <div class="col-12 text-center mb-2">
        <a href="https://ether.cards/privacy-policy.html" target="_blank">Privacy Policy</a>
      </div>
      <div className="footer-container container">
        <div id="copyright">
          <p><span style={{ fontSize: '18px' }}>©</span> 2021 All Rights Reserved</p>
          <img src={logo} style={{ height: '35px', opacity:'0.6' }}></img>
        </div>
      </div>
    </div>

  );
}
export default FooterComponent;