import React from "react"

export const styleSubstring = (text, substrings) => {
    if (substrings) {
        let styledText = text;

        substrings.forEach((substring) => {
            if (styledText.indexOf(substring) !== -1) {
                styledText =
                    styledText.slice(0, styledText.indexOf(substring)) +
                    `<span class="input-subtext">` +
                    substring +
                    `</span>` +
                    styledText.slice(
                        styledText.indexOf(substring) + substring.length,
                        styledText.length
                    );
            }
        });
        return <span dangerouslySetInnerHTML={{ __html: styledText }} />;
    } else return text;
};