import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useCombinedRefs } from "../../../utils/hooks";

// https://www.alltimepower.com/blog/react-hook-forms-custom-checkbox/

const CombinedRefRadioButton = forwardRef(
  (
    {
      customOnChange,
      onChange,
      onBlur,
      name,
      defaultChecked,
      value,
      newValue,
      children,
      setShowChecked,
    },
    forwardedRef
  ) => {
    const [checked, setChecked] = useState(defaultChecked || false);
    const innerRef = useRef(null);
    const combinedRef = useCombinedRefs(forwardedRef, innerRef);

    const setCheckedInput = (checked) => {
      if (combinedRef.current.checked !== checked) {
        combinedRef.current.checked = checked;
        let target = { target: innerRef.current };
        onChange(target);
        if (customOnChange) {
          customOnChange(target);
        }
      }
    };
    useEffect(() => {
      setCheckedInput(checked);
      setShowChecked(checked);
    }, [checked]);

    useEffect(() => {
      if (Number(newValue) !== Number(value)) {
        setChecked(false);
      }
    }, [newValue]);

    return (
      <div onClick={() => setChecked(!checked)} style={{ cursor: "pointer" }}>
        <input
          style={{ display: "none" }}
          ref={combinedRef}
          type="radio"
          name={name}
          value={value}
          defaultChecked={false}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            console.info(e);
          }}
        />
        {children}
      </div>
    );
  }
);

export default CombinedRefRadioButton;
