import React from 'react';
import './PrefooterComponent.css';
import emailSubscription from '../../assets/js/emailsubscription';
import discordLogo from '../../assets/images/discord.png';
import redditLogo from '../../assets/images/reddit.png';
import twitterLogo from '../../assets/images/twitter.png';
import successIcon from "../../assets/images/success.png";

const renderContactUs = () => {
  return (
    <div className="col-12 col-md-3">
      <h5>Contact Us</h5>
      <div className="social-icons">

        <a href="https://twitter.com/ether_cards" target="_blank"
        ><img src={twitterLogo} alt="twitter"
          /></a>
        <a href="https://discord.com/invite/mBwauRSJNW" target="_blank"
        ><img src={discordLogo} alt="discord"
          /></a>
        <a href="https://www.reddit.com/r/Ether_Cards/" target="_blank"
        ><img src={redditLogo} alt="discord"
          /></a>
      </div>
    </div>)
}

const renderModal = () => {
  return (
    <div className="modal fade" id="thankYouPopup" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              <span>×</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="thank-you-popup">
              <img height="40" src={successIcon} alt="Successful" />
              <h2>Thank You!</h2>
              <p>
                Your submission has been received! Hop over to our Discord to get
                daily bites of our upcoming launch!
            </p>
              <a
                className="btn btn-outline btn-purple"
                href="https://discord.com/invite/mBwauRSJNW"
                target="_blank"
              >Join Discord</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const PrefooterComponent = () => {

  return (
    <div id="pre-footer" className="container">
      <div className="row">
        <div className="col-10 offset-1 col-md-7 col-lg-5 offset-md-0">
          <div className="row">
            <div className="col newsletter-title">
              <h4>Join the Ether Cards<br />
                community to stay up to date
              </h4>
              <form id="register-form">
                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    placeholder="Email Address"
                    type="email"
                    name="email"
                    required
                  />
                  <button
                    id="submit-form"
                    className="btn btn-purple-fill"
                    type="submit"
                  >
                    Join Now <div className="spinner-border"></div>
                  </button>
                </div>
              </form>
              <p style={{ marginLeft: '20px', fontSize: '14px' }}>* Subscribe today for update</p>

            </div>
          </div>
        </div>

        <div className="col-lg-7">
          <div className="row px-5 px-md-0">
            <div className="col-4 col-md-3">
              <h5>Cards</h5>

              <ul>
                <li>
                  <a href={"https://ether.cards/cards"} target="_blank">Ether Cards</a>
                </li>
                <li>
                  <a href={"https://explorer.ether.cards"} target="_blank">Gallery/ Explorer</a>
                </li>
                <li>
                  <a href={"https://traits.ether.cards"} target="_blank">Traits</a>
                </li>
              </ul>

            </div>

            <div className="col-4 col-md-3">
              <h5>Platforms</h5>
              <ul>
                <li>
                  <a href={"https://ether.cards/events"} target="_blank">Events</a>
                </li>
                <li>
                  <a href={"https://bizdev.ether.cards"} target="_blank">Business</a>
                </li>
              </ul>


            </div>

            <div className="col-4 col-md-3">
              <h5>About</h5>
              <ul>
                <li>
                  <a href={"https://blog.ether.cards"} target="_blank">Blog</a>
                </li>
                <li>
                  <a href={"https://docs.ether.cards/faq"} target="_blank">FAQ</a>
                </li>
                <li>
                  <a href={"https://docs.ether.cards/team"} target="_blank">Team</a>
                </li>
              </ul>
            </div>

            {renderContactUs()}
          </div>
        </div>
      </div>
      {renderModal()}
    </div>
  );
}
export default PrefooterComponent;