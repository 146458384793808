import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useCombinedRefs } from "../../../utils/hooks";

// https://www.alltimepower.com/blog/react-hook-forms-custom-checkbox/

const CombinedRefCheckbox = forwardRef(
  (
    {
      customOnChange,
      onChange,
      onBlur,
      name,
      defaultChecked,
      value,
      newValues,
      children,
      setShowChecked,
    },
    forwardedRef
  ) => {
    const [checked, setChecked] = useState(defaultChecked || false);
    const innerRef = useRef(null);
    const combinedRef = useCombinedRefs(forwardedRef, innerRef);

    const setCheckedInput = (checked) => {
      if (combinedRef.current.checked !== checked) {
        // just emulate an actual click on the input element
        combinedRef.current.click();
      }
    };
    useEffect(() => {
      setCheckedInput(checked);
      setShowChecked(checked);
    }, [checked]);

    useEffect(() => {
      if (
        newValues &&
        newValues.length > 0 &&
        newValues.indexOf(String(value)) !== -1
      ) {
        // console.info(value);
        // console.info(newValues);
        setChecked(true);
      } else {
        setChecked(false);
      }
    }, [newValues]);

    return (
      <div onClick={() => setChecked(!checked)} style={{ cursor: "pointer" }}>
        <input
          style={{ display: "none" }}
          ref={combinedRef}
          type="checkbox"
          name={name}
          value={value}
          defaultChecked={false}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            onChange(e);
            if (customOnChange) {
              customOnChange(e);
            }
            //console.info("onChanging", e.target.checked);
          }}
        />
        {children}
      </div>
    );
  }
);

export default CombinedRefCheckbox;
