import React, { useState } from "react";

const InputTextArea = ({
  title,
  register,
  name,
  errors,
  enable,
  maxlength,
}) => {
  const [charCount, setCharCount] = useState(0);
  const { name: registerName, onChange, onBlur, ref } = register;

  return (
    <>
      <div className="input-title">{title}</div>
      <div
        className={`input-container ${
          enable != null ? (enable ? "" : "disabled") : ""
        }`}
      >
        <textarea
          className={`${enable ? "" : "disabled"}`}
          name={registerName}
          onBlur={onBlur}
          ref={ref}
          maxLength={maxlength}
          placeholder={"Your answer"}
          disabled={enable != null ? !enable : false}
          onChange={(e) => {
            onChange(e);
            setCharCount(e.target.value.split("").length);
          }}
        />
        <div className="row px-0 justify-space-between">
          <div className="col-auto col-sm-9">
            {errors[name] && (
              <div className="input-error">{errors[name].message}</div>
            )}
          </div>
          <div className="col-auto col-sm-3">
            <div
              className="text-right"
              style={{ fontSize: "12px" }}
            >{`${charCount}/1000`}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InputTextArea;
