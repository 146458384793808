import React, { useEffect } from 'react';
import { createStore } from './store.jsx';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';

import FooterComponent from './components/Footer/FooterComponent';
import PrefooterComponent from './components/Prefooter/PrefooterComponent';
import {ECNav} from 'ec-commons';
import Navigation from './components/Navigation/Navigation';
import routes from './Routes';

// Import bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './App.css';

const getPathname = () => {
  try {
    return new URL(PUBLIC_URL).pathname;
  } catch (e) {
    return "/"
  }
}

let publicPath = getPathname();

console.info(publicPath)

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.querySelector(".App").scrollTo(0, 0)
  }, [pathname]);

  return null;
}

const App = () => {
  return (
    <div className="App">
      <BrowserRouter basename={publicPath}>
        {/* <Navigation /> */}
        <ECNav projectUrl="https://bizdev.ether.cards"  lightMode disableShadow/>
        <Switch>
          {routes.map((route, key) => (
            <Route key={key} path={route.path} component={route.component} exact={route.exact} />
          ))}
        </Switch>

        <footer>
          <PrefooterComponent />
          <FooterComponent />
        </footer>
        <ScrollToTop />
      </BrowserRouter>
    </div>
  );
}

export default createStore(App);
