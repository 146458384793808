import React from "react";
const InputText = ({ title, register, name, errors, maxlength }) => {
  return (
    <>
      <div className="input-title">{title}</div>
      <div className="input-container">
        <input
          maxLength={maxlength}
          type="text"
          placeholder={"Your answer"}
          {...register}
        ></input>
        {errors[name] && (
          <div className="input-error">{errors[name].message}</div>
        )}
      </div>
    </>
  );
};

export default InputText;
