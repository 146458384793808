// q1
export const COMPANY_SIZE = "company_size";
export const COMPANY_SIZE_ANSWERS = ["\<10", "10-50", "51-200", "201\+"];

// q2
export const INDUSTRY = "industry";
export const INDUSTRY_OTHER = "industry_other"
export const INDUSTRY_ANSWERS = [
    "Advertising",
    "Automotive",
    "B2B Services",
    "Biotechnology",
    "Blockchain",
    "Communications",
    "Consulting",
    "Cryptocurrency",
    "DAO",
    "DEX",
    "Education",
    "Energy",
    "Entertainment",
    "Environment",
    "Finance",
    "Healthcare",
    "Insurance",
    "Not For Profit",
    "Shipping",
    "Sports",
    "Other",
];
// q3
export const NFT_TYPE = "nft_type";
export const NFT_TYPE_OTHER = "nft_type_other";
export const NFT_TYPE_ANSWERS = [
    "Membership Cards",
    "Subscription",
    "Fan Club/Rewards Program",
    "A Collectible (art pieces, sports memorabilia, music records, short films, games etc.)",
    "Other",
];
export const NFT_TYPE_ANSWERS_STYLE = [
    "(art pieces, sports memorabilia, music records, short films, games etc.)",
];
// q4
export const FEATURES_1 = "features_1";
export const FEATURES_1_OTHER = "features_1_other";
export const FEATURES_1_ANSWERS = [
    "Discounts on future drops",
    "Exclusive access rights (eg. personalised chat rooms, events, etc.)",
    "Gamification",
    "Merchandise drops",
    "An NFT that transforms according to real world events (eg. Sports/ weather/ sales data)",
    "None",
    "Other",
];
export const FEATURES_1_ANSWERS_STYLE = [
    "(eg. personalised chat rooms, events, etc.)",
    "(eg. Sports/ weather/ sales data)",
];


// q5
export const FEATURES_2 = "features_2";
export const FEATURES_2_ANSWERS = [
    "Personalised domain name",
    "Sales Launch Capabilities (countdown timers, mailing lists, alerts, etc.)",
    "Tier System",
    "Marketplace",
    "Auctions",
    "Other",
];
export const FEATURES_2_ANSWERS_STYLE = [
    "(countdown timers, mailing lists, alerts, etc.)",
];
export const FEATURES_2_OTHER = "features_2_other";

// q6
export const PROJECT_DESC = "project_desc";

// q7
export const NFT_PROJECT_BUDGET = "nft_project_budget";
export const NFT_PROJECT_BUDGET_ANSWERS = ["> $10,000"];


// q8
export const TIME_TO_LAUNCH = "time_to_launch";
export const TIME_TO_LAUNCH_ANSWERS = [
    "Immediately",
    "1-2 weeks",
    "3\+ weeks",
    "Decide Later",
];

// q9
export const LENGTH_OF_SALE = "length_of_sale";
export const LENGTH_OF_SALE_ANSWERS = [
    "less than 1 month",
    "1 to 3 months",
    "3 to 6 months",
    "6\+ months",
    "Decide Later",
];

// q10
export const ENGAGEMENT_LEVEL = "engagement_level";
export const ENGAGEMENT_LEVEL_ANSWERS = [
    "Custom website",
    "Dynamic NFTs",
    "Microsite design assets",
    "Traits for existing NFTs",
];

// q11
export const NFT_SALE_EXPERIENCE = "nft_sale_experience";
export const NFT_SALE_EXPERIENCE_ANSWERS = [
    "None, wanting to learn more",
    "I have assets and no NFT sale experience",
    "I have previously sold on NFT marketplaces",
];

// q12
export const HEAR_SOURCE = "hear_source";
export const HEAR_SOURCE_ANSWERS = [
    "LaMeLoBall.io",
    "Chainlink",
    "Brave",
    "Discord",
    "Online Article",
    "Media Interview",
    "LinkedIn",
    "Twitter",
    "Google",
    "Angel List",
    "Webinar",
    "Referral",
    "Facebook",
    "Instagram",
    "Other",
];
export const HEAR_SOURCE_OTHER = "hear_source_other";

// q13
export const FIRST_NAME = "first_name";

// q14
export const LAST_NAME = "last_name";

// q15
export const COMPANY = "company";

// q16
export const WEBSITE = "website";

// q17
export const PHONE = "phone";

// q18
export const EMAIL = "email";


export const SALESFORCE_FIELD_ID_MAP = {
    [COMPANY_SIZE]: "00N5e00000RFprq",
    [INDUSTRY]: INDUSTRY,
    [INDUSTRY_OTHER]: "00N5e00000bI28V",
    [NFT_TYPE]: "00N5e00000RGhaR",
    [NFT_TYPE_OTHER]: "00N5e00000bI4Oq",
    [FEATURES_1]: "00N5e00000RHKiq",
    [FEATURES_1_OTHER]: "00N5e00000bI4U2",
    [FEATURES_2]: "00N5e00000RHM6E",
    [FEATURES_2_OTHER]: "00N5e00000bI60F",
    [PROJECT_DESC]: "00N5e00000RHLwo",
    [NFT_PROJECT_BUDGET]: "00N5e00000RGhfS",
    [TIME_TO_LAUNCH]: "00N5e00000RGnGz",
    [LENGTH_OF_SALE]: "00N5e00000RGnJ7",
    [ENGAGEMENT_LEVEL]: "00N5e00000RH8Zt",
    [NFT_SALE_EXPERIENCE]: "00N5e00000RH8eH",
    [HEAR_SOURCE]: "00N5e00000RHIwZ",
    [HEAR_SOURCE_OTHER]: "00N5e00000bHx1S",
    [FIRST_NAME]: FIRST_NAME,
    [LAST_NAME]: LAST_NAME,
    [COMPANY]: COMPANY,
    [WEBSITE]: WEBSITE,
    [PHONE]: PHONE,
    [EMAIL]: EMAIL,
    "g-recaptcha-response": "g-recaptcha-response"
};
