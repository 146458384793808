import axios from 'axios';
import config from '../config';

const defaults = {
    baseURL: config.SERVER_URL,
    headers: () => ({
        'Content-Type': 'application/json',
    }),
    error: {
        code: 'INTERNAL_ERROR',
        message: 'Something went wrong. Please check your internet connection',
        status: 503,
        data: {},
    },
};

const api = (method, url, variables, fullUrl) =>
    new Promise((resolve, reject) => {
        let resolveUrl = fullUrl ? url : `${defaults.baseURL}${url}`;
        axios({
            url: resolveUrl,
            method,
            headers: defaults.headers(),
            params: method === 'get' ? variables : undefined,
            data: method !== 'get' ? variables : undefined,
        }).then(
            response => {
                resolve(response.data);
            },
            error => {
                console.info(error)
                if (error.response) {
                    reject(error.response.data.message);
                } else {
                    reject(defaults.error);
                }
            },
        );
    });

export default {
    get: (...args) => api('get', ...args),
    post: (...args) => api('post', ...args),
    put: (...args) => api('put', ...args),
    patch: (...args) => api('patch', ...args),
    delete: (...args) => api('delete', ...args),
};
