// import BizForm from './components/Forms/BizForm';
// import BizForm2 from './components/Forms/BizForm2.jsx';
import BizForm3 from './components/Forms/BizForm3.jsx';
import BizSaleForm from './components/Forms/BizSaleForm.jsx';

import Home from './components/Home/Home';

const routes = [
     {
          'path': '/',
          'component': Home,
          'exact': true
     }, {
          'path': '/form',
          'component': BizSaleForm,
          'exact': true
     },
     {
          'path': '/sale-form',
          'component': BizSaleForm,
          'exact': true
     },
];

export default routes;

