import React from "react";
import successIcon from "../../assets/images/success.png";

const DiscordPopup = ({ showModal, closeModal }) => {
  return (
    <div
      className={`modal fade ${showModal && "show"}`}
      id="thankYouPopup"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close p-0"
              onClick={() => closeModal()}
            >
              <span>×</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="thank-you-popup">
              <img height="40" src={successIcon} alt="Successful" />
              <h2>Thank You!</h2>
              <p>
                Your submission has been received! Hop over to our Discord to
                get daily bites of our upcoming launch!
              </p>
              <a
                className="btn btn-outline btn-purple"
                href="https://discord.com/invite/mBwauRSJNW"
                target="_blank"
              >
                Join Discord
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="overlay" onClick={() => closeModal()}></div>
    </div>
  );
};

export default DiscordPopup;
