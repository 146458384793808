import React, { useState, useRef } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useRecaptcha } from "react-hook-recaptcha";
import api from "../../api";
import config from "../../config";
import axios from "axios";
import { SpinnerDotted } from "spinners-react";

import { SALESFORCE_FIELD_ID_MAP } from "./constants/salesforce";

import { REQUIRED } from "./constants/error";

import { getBizSaleRegisters } from "./util";

import {
  InputTextArea,
  InputText,
  TitleInputRadio,
  TitleInputCheckbox,
} from "./HookFormComponents";

import { DiscordPopup } from "../Modal";

import formImage from "../../assets/images/form-image.png";

import "./Forms.css";

const FORM_URL =
  "https://script.google.com/macros/s/AKfycbxsZNmNV0C8i0s5kmLPNiZAviNAcv03aUPjamGpSIm5oQN4KEIiZR8cipeU4-KoOnPTsA/exec";

const SALESFORCE_FORM_URL =
  "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8";

const salesFormParam = () => {
  return {
    oid: "00D5e000001P3b1",
    // retURL: "https://bizdev.ether.cards",
    captcha_settings: `{"keyname":"bizformv2","fallback":"true","orgId":"00D5e000001P3b1","ts":${JSON.stringify(
      new Date().getTime()
    )}}`,
  };
};

const CAPTCHA_SITE_KEY = "6Lfc7b4bAAAAAHxcfJhk7s8mcaB7Ve7-YV9Fg9qp";
const CAPTCHA_CONTAINER_ID = "captchaContainer";
const CAPTCHA_PARAM = "g-recaptcha-response";

const BizSaleForm = (props) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  // const watchNftTypeOther = useWatch({
  //   name: NFT_TYPE,
  //   control: control,
  //   defaultValue: [],
  // });

  const registers = getBizSaleRegisters(register, getValues);

  // ============== RECAPTCHA STUFF ==============
  register(CAPTCHA_PARAM, { required: REQUIRED });

  const captchaCallback = (response) => {
    setValue(CAPTCHA_PARAM, response, {
      shouldValidate: true,
    });
  };

  const { recaptchaLoaded } = useRecaptcha({
    containerId: CAPTCHA_CONTAINER_ID,
    successCallback: captchaCallback,
    expiredCallback: captchaCallback,
    sitekey: CAPTCHA_SITE_KEY,
  });
  // ==============================================

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [enableIndustryOther, setEnableIndustryOther] = useState(false);
  const [enableNftTypeOther, setEnableNftTypeOther] = useState(false);
  const [enableFeature1Other, setEnableFeature1Other] = useState(false);
  const [enableFeature2Other, setEnableFeature2Other] = useState(false);
  const [enableHearSourceOther, setEnableHearSourceOther] = useState(false);
  const [clickedSubmit, setClickedSubmit] = useState(false);

  const customOnChange = (fieldName, fieldOther, answers, setEnable) => {
    const values = getValues(fieldName);
    const otherChecked =
      values && values.indexOf(String(answers.length - 1)) !== -1;
    if (!otherChecked) {
      setValue(fieldOther, "", { shouldValidate: true });
      setEnable(false);
    } else {
      setEnable(true);
    }
  };

  const industryCustomOnChange = () =>
    customOnChange(
      registers.INDUSTRY.field,
      registers.INDUSTRY_OTHER.field,
      registers.INDUSTRY.answers,
      setEnableIndustryOther
    );
  const nftTypeCustomOnChange = () =>
    customOnChange(
      registers.NFT_TYPE.field,
      registers.NFT_TYPE_OTHER.field,
      registers.NFT_TYPE.answers,
      setEnableNftTypeOther
    );

  const features2CustomOnChange = () =>
    customOnChange(
      registers.FEATURES_2.field,
      registers.FEATURES_2_OTHER.field,
      registers.FEATURES_2.answers,
      setEnableFeature2Other
    );

  const hearSourceCustomOnChange = () =>
    customOnChange(
      registers.HEAR_SOURCE.field,
      registers.HEAR_SOURCE_OTHER.field,
      registers.HEAR_SOURCE.answers,
      setEnableHearSourceOther
    );

  const features1CustomOnChange = (e) => {
    const field = registers.FEATURES_1.field;
    const fieldOther = registers.FEATURES_1_OTHER.field;
    const answers = registers.FEATURES_1.answers;

    const onChangeValue = e.target.value;
    const checked = e.target.checked;
    const noneValue = String(answers.length - 2);
    const otherValue = String(answers.length - 1);
    const onChangeIsNone = onChangeValue == noneValue;

    const values = getValues(field);
    const otherChecked = values && values.indexOf(otherValue) !== -1;
    const noneChecked = values && values.indexOf(noneValue) !== -1;

    if (noneChecked && !onChangeIsNone) {
      // Remove none from values
      setValue(field, [...values.filter((v) => v != noneValue)], {
        shouldValidate: true,
      });
    } else if (onChangeIsNone && checked) {
      // Remove all except none
      setValue(field, [noneValue], {
        shouldValidate: true,
      });
    }
    // Disable Other Field when other is unchecked or none is checked
    if (!otherChecked || (onChangeIsNone && checked)) {
      setValue(fieldOther, "", { shouldValidate: true });
      setEnableFeature1Other(false);
    } else {
      setEnableFeature1Other(true);
    }
  };

  // console.info(watch(IAM));
  // console.info(watch(ESTIMATE_LAUNCH_DATE));
  // console.info(errors);

  const onClickBack = () => {
    props.history.push({
      pathname: "/",
    });
  };

  const mapCheckboxValuesToField = (values, answers, other) => {
    if (values && values.length > 0)
      return values
        .map((index) => {
          // if (index == answers.length - 1 && other != null) {
          //   return `${answers[index]}(${other})`;
          // }
          return answers[index];
        })
        .join(";");
  };

  const mapRadioValueToField = (value, answers, other) => {
    // if (value == answers.length - 1 && other != null) {
    //   return `${answers[value]}(${other})`;
    // }
    return answers[value];
  };

  const onSubmit = async (data) => {
    if (!loading) {
      let submitData = { ...data };
      // console.log(data);

      // Map radio and checkbox answers
      submitData[registers.COMPANY_SIZE.field] = mapRadioValueToField(
        submitData[registers.COMPANY_SIZE.field],
        registers.COMPANY_SIZE.answers
      );

      // Other
      submitData[registers.INDUSTRY.field] = mapRadioValueToField(
        submitData[registers.INDUSTRY.field],
        registers.INDUSTRY.answers
        // submitData[registers.INDUSTRY_OTHER.field]
      );

      // Other
      submitData[registers.NFT_TYPE.field] = mapCheckboxValuesToField(
        submitData[registers.NFT_TYPE.field],
        registers.NFT_TYPE.answers
        // submitData[registers.NFT_TYPE_OTHER.field]
      );

      // Other
      submitData[registers.FEATURES_1.field] = mapCheckboxValuesToField(
        submitData[registers.FEATURES_1.field],
        registers.FEATURES_1.answers
        // submitData[registers.FEATURES_1_OTHER.field]
      );

      // Other
      submitData[registers.FEATURES_2.field] = mapCheckboxValuesToField(
        submitData[registers.FEATURES_2.field],
        registers.FEATURES_2.answers
        // submitData[registers.FEATURES_2_OTHER.field]
      );

      submitData[registers.NFT_PROJECT_BUDGET.field] = mapRadioValueToField(
        submitData[registers.NFT_PROJECT_BUDGET.field],
        registers.NFT_PROJECT_BUDGET.answers
      );

      submitData[registers.TIME_TO_LAUNCH.field] = mapRadioValueToField(
        submitData[registers.TIME_TO_LAUNCH.field],
        registers.TIME_TO_LAUNCH.answers
      );

      submitData[registers.LENGTH_OF_SALE.field] = mapRadioValueToField(
        submitData[registers.LENGTH_OF_SALE.field],
        registers.LENGTH_OF_SALE.answers
      );

      submitData[registers.ENGAGEMENT_LEVEL.field] = mapCheckboxValuesToField(
        submitData[registers.ENGAGEMENT_LEVEL.field],
        registers.ENGAGEMENT_LEVEL.answers
      );

      submitData[registers.NFT_SALE_EXPERIENCE.field] = mapRadioValueToField(
        submitData[registers.NFT_SALE_EXPERIENCE.field],
        registers.NFT_SALE_EXPERIENCE.answers
      );

      // Other
      submitData[registers.HEAR_SOURCE.field] = mapRadioValueToField(
        submitData[registers.HEAR_SOURCE.field],
        registers.HEAR_SOURCE.answers
        // submitData[registers.HEAR_SOURCE_OTHER.field]
      );

      // console.log(submitData);

      setLoading(true);
      const gsheetData = { ...submitData };
      gsheetData.id = config.BIZFORM3_ID;

      // await submitWebToLead(submitData);
      // submit to google form then salesforce
      await api
        .get(FORM_URL, gsheetData, true)
        .then((res) => {
          // console.info(res);
          if (res.result == "error") {
            alert("Something went wrong! : " + res.error);
          } else {
            console.log("google sheet submission successful");
            return submitWebToLead(submitData);
          }
        })
        .catch((e) => {
          console.error(e);
          alert("Something went wrong!");
        });

      setLoading(false);
    }
  };

  const submitWebToLead = async (submitData) => {
    const salesForceData = {
      ...mapSalesforceId(submitData),
      ...salesFormParam(),
      debug: 1,
      debugEmail: "kinsun@ether.cards",
    };
    // console.log(salesForceData);
    // webToLead(salesForceData);

    // const formData = new FormData();
    let queryStrings = "";
    Object.entries(salesForceData).forEach((value) => {
      // formData.append(value[0], value[1] ? value[1] : "");
      queryStrings =
        queryStrings +
        `&${encodeURIComponent(value[0])}=${
          value[1] ? encodeURIComponent(value[1]) : ""
        }`;
    });

    // Bypass CORS on salesforce
    return fetch(SALESFORCE_FORM_URL + queryStrings, {
      method: "POST",
      mode: "no-cors",
      // body: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        console.log("webtolead successful");
        setShowModal(true);
      })
      .catch((e) => {
        console.error(e);
        alert("Something went wrong!");
      });
  };

  const mapSalesforceId = (submitData) => {
    const mappedIdData = {};

    Object.keys(SALESFORCE_FIELD_ID_MAP).forEach((key) => {
      // Single checkbox answer, if exists, value = 1
      // Salesforce expecting value=1 for single checkbox
      if (key == registers.NFT_PROJECT_BUDGET.field) {
        mappedIdData[SALESFORCE_FIELD_ID_MAP[key]] = submitData[key] && 1;
      } else {
        mappedIdData[SALESFORCE_FIELD_ID_MAP[key]] = submitData[key];
      }
    });

    return mappedIdData;
  };

  // Workaround for submitting form in js
  const webToLead = (salesForceData) => {
    var form = document.createElement("form");
    form.method = "POST";
    form.action = SALESFORCE_FORM_URL;

    Object.entries(salesForceData).forEach((value) => {
      const element = document.createElement("input");
      element.name = value[0];
      element.value = value[1] ? value[1] : "";
      element.setAttribute("type", "hidden");
      form.appendChild(element);
    });

    document.body.appendChild(form);

    form.submit();
  };

  const closeModal = () => {
    props.history.replace({ ...props.history.location, state: null });
    props.history.push({
      pathname: "/",
    });
    setShowModal(false);
  };

  return (
    <div id="bizForm3" className="container mt-5">
      <div className="col-4 offset-4">
        <img className="w-100" src={formImage}></img>
      </div>
      <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 px-0 px-md-3">
        <div
          className="p-3 p-lg-5"
          ref={(el) => {
            if (el) {
              el.style.setProperty("padding-top", 0, "important");
            }
          }}
        >
          <div className="header-title">
            Let's get started building your dynamic NFT collection.
          </div>
          <p className="mt-5">
            Create cards with Traits that fit the unique qualities of your
            project. Traits can give cardholders things like discounts, special
            access, redeemable physical objects, connections to real-world
            events, airdrops, upgrades, and other benefits. Tell us about what
            you’re doing, and we’ll work with you to build custom Traits for
            your dynamic NFT collection.
          </p>
        </div>

        <form
          id="bizContactForm"
          className="form-card"
          onSubmit={(e) => {
            e.preventDefault();
            if (!clickedSubmit) {
              setClickedSubmit(true);
            }
            handleSubmit(onSubmit)(e);
          }}
        >
          <div className="row biz-form">
            <div className="col-12">
              <h2 className="mb-4">Business Contact Form</h2>

              {/* Q1 */}
              <TitleInputRadio
                title={"1. Company Size"}
                field={registers.COMPANY_SIZE.field}
                answers={registers.COMPANY_SIZE.answers}
                register={registers.COMPANY_SIZE.register}
                errors={errors}
                watch={watch}
              />

              {/* Q2 */}
              <TitleInputRadio
                title={
                  <span>
                    2. Industry <span style={{ color: "red" }}>*</span>
                  </span>
                }
                field={registers.INDUSTRY.field}
                answers={registers.INDUSTRY.answers}
                register={registers.INDUSTRY.register}
                errors={errors}
                watch={watch}
                customOnChange={industryCustomOnChange}
              />
              <InputTextArea
                register={registers.INDUSTRY_OTHER.register}
                name={registers.INDUSTRY_OTHER.field}
                maxlength={255}
                errors={errors}
                enable={enableIndustryOther}
              />

              {/* Q3 */}
              <TitleInputCheckbox
                title={
                  <span>
                    3. Which of the following best describes your Dynamic NFT
                    Project?
                    <br></br>
                    <span className="input-subtext">
                      (select all that applies)
                    </span>
                  </span>
                }
                field={registers.NFT_TYPE.field}
                answers={registers.NFT_TYPE.answers}
                register={registers.NFT_TYPE.register}
                errors={errors}
                watch={watch}
                customOnChange={nftTypeCustomOnChange}
                substringToStyle={registers.NFT_TYPE.substringToStyle}
              />
              <InputTextArea
                register={registers.NFT_TYPE_OTHER.register}
                name={registers.NFT_TYPE_OTHER.field}
                maxlength={255}
                errors={errors}
                enable={enableNftTypeOther}
              />

              {/* Q4 */}
              <TitleInputCheckbox
                title={
                  <span>
                    4. Are there features you would like to provide to your
                    dynamic NFT collectors? {"  "}
                    <span className="input-subtext">
                      (select those that best apply to your project)
                    </span>
                  </span>
                }
                field={registers.FEATURES_1.field}
                answers={registers.FEATURES_1.answers}
                register={registers.FEATURES_1.register}
                errors={errors}
                watch={watch}
                customOnChange={features1CustomOnChange}
                substringToStyle={registers.FEATURES_1.substringToStyle}
              />
              <InputTextArea
                register={registers.FEATURES_1_OTHER.register}
                name={registers.FEATURES_1_OTHER.field}
                maxlength={255}
                errors={errors}
                enable={enableFeature1Other}
              />

              {/* Q5 */}
              <TitleInputCheckbox
                title={
                  <span>
                    5. Features you wish to have on your personalised NFT
                    platform
                    <br></br>
                    <span className="input-subtext">
                      (eg. www.mydynamicnfts.com)
                    </span>
                    {"  "}
                    <span style={{ color: "red" }}>*</span>
                  </span>
                }
                field={registers.FEATURES_2.field}
                answers={registers.FEATURES_2.answers}
                register={registers.FEATURES_2.register}
                errors={errors}
                watch={watch}
                customOnChange={features2CustomOnChange}
                substringToStyle={registers.FEATURES_2.substringToStyle}
              />
              <InputTextArea
                register={registers.FEATURES_2_OTHER.register}
                name={registers.FEATURES_2_OTHER.field}
                maxlength={255}
                errors={errors}
                enable={enableFeature2Other}
              />

              {/* Q6 */}
              <InputTextArea
                title={
                  <>
                    8. Project Description{"  "}
                    <span className="input-subtext">
                      (please be as detailed as possible)
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                maxlength={1000}
                register={registers.PROJECT_DESC.register}
                name={registers.PROJECT_DESC.field}
                errors={errors}
              />

              {/* Q7 */}
              <TitleInputCheckbox
                title={"7. NFT Project Budget"}
                field={registers.NFT_PROJECT_BUDGET.field}
                answers={registers.NFT_PROJECT_BUDGET.answers}
                register={registers.NFT_PROJECT_BUDGET.register}
                errors={errors}
                watch={watch}
              />

              {/* Q8 */}
              <TitleInputRadio
                title={
                  <>
                    8. Time To Launch <span style={{ color: "red" }}>*</span>
                  </>
                }
                field={registers.TIME_TO_LAUNCH.field}
                answers={registers.TIME_TO_LAUNCH.answers}
                register={registers.TIME_TO_LAUNCH.register}
                errors={errors}
                watch={watch}
              />

              {/* Q9 */}
              <TitleInputRadio
                title={
                  <>
                    9. Length of NFT Project Sale{" "}
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                field={registers.LENGTH_OF_SALE.field}
                answers={registers.LENGTH_OF_SALE.answers}
                register={registers.LENGTH_OF_SALE.register}
                errors={errors}
                watch={watch}
              />

              {/* Q10 */}
              <TitleInputCheckbox
                title={"10. Level of Ether Cards Engagement"}
                field={registers.ENGAGEMENT_LEVEL.field}
                answers={registers.ENGAGEMENT_LEVEL.answers}
                register={registers.ENGAGEMENT_LEVEL.register}
                errors={errors}
                watch={watch}
              />

              {/* Q11 */}
              <TitleInputRadio
                title={
                  <>
                    11. Level of NFT Sale Experience{" "}
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                field={registers.NFT_SALE_EXPERIENCE.field}
                answers={registers.NFT_SALE_EXPERIENCE.answers}
                register={registers.NFT_SALE_EXPERIENCE.register}
                errors={errors}
                watch={watch}
              />

              {/* Q12 */}
              <TitleInputRadio
                title={
                  <>
                    12. How Did You Hear About Us?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                field={registers.HEAR_SOURCE.field}
                answers={registers.HEAR_SOURCE.answers}
                register={registers.HEAR_SOURCE.register}
                customOnChange={hearSourceCustomOnChange}
                errors={errors}
                watch={watch}
              />
              <InputTextArea
                register={registers.HEAR_SOURCE_OTHER.register}
                name={registers.HEAR_SOURCE_OTHER.field}
                maxlength={255}
                errors={errors}
                enable={enableHearSourceOther}
              />

              <div className="row">
                <div className="col-6">
                  {/* Q13 */}
                  <InputText
                    title={
                      <>
                        13. First Name<span style={{ color: "red" }}>*</span>
                      </>
                    }
                    maxlength={80}
                    register={registers.FIRST_NAME.register}
                    name={registers.FIRST_NAME.field}
                    errors={errors}
                  />
                </div>
                <div className="col-6">
                  {/* Q14 */}
                  <InputText
                    title={
                      <>
                        14. Last Name<span style={{ color: "red" }}>*</span>
                      </>
                    }
                    maxlength={80}
                    register={registers.LAST_NAME.register}
                    name={registers.LAST_NAME.field}
                    errors={errors}
                  />
                </div>
              </div>

              {/* Q15 */}
              <InputText
                title={
                  <>
                    15. Company <span style={{ color: "red" }}>*</span>
                  </>
                }
                maxlength={80}
                register={registers.COMPANY.register}
                name={registers.COMPANY.field}
                errors={errors}
              />

              {/* Q16 */}
              <InputText
                title={"16. Website"}
                maxlength={80}
                register={registers.WEBSITE.register}
                name={registers.WEBSITE.field}
                errors={errors}
              />

              {/* Q17 */}
              <InputText
                title={"17. Phone"}
                maxlength={80}
                register={registers.PHONE.register}
                name={registers.PHONE.field}
                errors={errors}
              />

              {/* Q18 */}
              <InputText
                title={
                  <>
                    18. Email <span style={{ color: "red" }}>*</span>
                  </>
                }
                maxlength={80}
                register={registers.EMAIL.register}
                name={registers.EMAIL.field}
                errors={errors}
              />

              <div className="mt-4 px-0">
                <div id={CAPTCHA_CONTAINER_ID} />
                {errors && errors[CAPTCHA_PARAM] && (
                  <div className="input-error">
                    {errors[CAPTCHA_PARAM].message}
                  </div>
                )}
                {!recaptchaLoaded && (
                  <p>
                    <span className="mr-2">Loading Captcha...</span>
                    <SpinnerDotted
                      size={20}
                      thickness={160}
                      speed={100}
                      color="#ad33a1"
                    />
                  </p>
                )}
              </div>
              {Object.keys(errors).length > 0 && clickedSubmit && (
                <div className="input-error mt-3">
                  Please ensure that ALL mandatory fields are filled. Then, tap
                  Submit again.
                </div>
              )}
            </div>
          </div>
        </form>
        <div className="text-left input-subtext">
          <span className="input-title" style={{ color: "red" }}>
            *
          </span>{" "}
          Required
        </div>
      </div>

      <div className="col-12 text-center mt-5 mb-5">
        <button
          className={`btn btn-action btn-outline mr-1`}
          onClick={() => onClickBack()}
        >
          Back
        </button>
        <button
          type="submit"
          form="bizContactForm"
          className={`btn btn-action btn-outline ml-1`}
          disabled={!recaptchaLoaded}
        >
          Submit
          <span
            className={`spinner-border ml-2 ${loading ? "loading" : ""}`}
          ></span>
        </button>
      </div>

      <DiscordPopup showModal={showModal} closeModal={closeModal} />
    </div>
  );
};

export default BizSaleForm;
