import React from "react";
import { InputCheckbox } from "./index";
import { styleSubstring } from "../util";

const TitleInputCheckbox = ({
  title,
  field,
  answers,
  register,
  errors,
  watch,
  customOnChange,
  substringToStyle,
}) => {
  return (
    <>
      <div className="input-title">{title}</div>
      {errors && errors[field] && (
        <div className="input-error">{errors[field].message}</div>
      )}
      <div className="scrollable-checkboxes">
        {answers.map((answer, index) => {
          return (
            <InputCheckbox
              key={answer + index} // Mapping Key
              title={answer} // Label
              register={register}
              name={field} // Input Param Name
              value={index} // Input Value
              newValues={watch(field)}
              customOnChange={customOnChange}
              substringToStyle={substringToStyle}
              styleSubstring={styleSubstring}
            />
          );
        })}
      </div>
    </>
  );
};

export default TitleInputCheckbox;
