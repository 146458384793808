import {
    COMPANY_SIZE,
    COMPANY_SIZE_ANSWERS,
    INDUSTRY,
    INDUSTRY_OTHER,
    INDUSTRY_ANSWERS,
    NFT_TYPE,
    NFT_TYPE_OTHER,
    NFT_TYPE_ANSWERS,
    NFT_TYPE_ANSWERS_STYLE,
    FEATURES_1,
    FEATURES_1_OTHER,
    FEATURES_1_ANSWERS,
    FEATURES_1_ANSWERS_STYLE,
    FEATURES_2,
    FEATURES_2_ANSWERS,
    FEATURES_2_ANSWERS_STYLE,
    FEATURES_2_OTHER,
    PROJECT_DESC,
    NFT_PROJECT_BUDGET,
    NFT_PROJECT_BUDGET_ANSWERS,
    TIME_TO_LAUNCH,
    TIME_TO_LAUNCH_ANSWERS,
    LENGTH_OF_SALE,
    LENGTH_OF_SALE_ANSWERS,
    ENGAGEMENT_LEVEL,
    ENGAGEMENT_LEVEL_ANSWERS,
    NFT_SALE_EXPERIENCE,
    NFT_SALE_EXPERIENCE_ANSWERS,
    HEAR_SOURCE,
    HEAR_SOURCE_ANSWERS,
    HEAR_SOURCE_OTHER,
    FIRST_NAME,
    LAST_NAME,
    COMPANY,
    WEBSITE,
    PHONE,
    EMAIL,
} from "../constants/salesforce";

import {
    REQUIRED,
    VALID_EMAIL,
    ATLEAST_ONE,
    VALID_PHONE,
    NO_EMOJI,
} from "../constants/error";

import {
    isValidPhoneNumber,
    isPossiblePhoneNumber
} from "react-phone-number-input"

export const getBizSaleRegisters = (register, getValues) => {

    const validateOther = (field, fieldOther, answers) => {
        const values = getValues(field);
        const otherValue = getValues(fieldOther);
        const otherChecked =
            values && values.indexOf(String(answers.length - 1)) !== -1;
        if (otherChecked && (otherValue == null || otherValue.trim() == "")) {
            return REQUIRED;
        }
    }

    return {
        COMPANY_SIZE: {
            field: COMPANY_SIZE,
            answers: COMPANY_SIZE_ANSWERS,
            register: register(COMPANY_SIZE)
        },
        INDUSTRY: {
            field: INDUSTRY,
            answers: INDUSTRY_ANSWERS,
            register: register(INDUSTRY, { required: REQUIRED })
        },
        INDUSTRY_OTHER: {
            field: INDUSTRY_OTHER,
            register: register(INDUSTRY_OTHER, {
                validate: validateOther(INDUSTRY, INDUSTRY_OTHER, INDUSTRY_ANSWERS)
            }),
        },
        NFT_TYPE: {
            field: NFT_TYPE,
            answers: NFT_TYPE_ANSWERS,
            substringToStyle: NFT_TYPE_ANSWERS_STYLE,
            register: register(NFT_TYPE)
        },
        NFT_TYPE_OTHER: {
            field: NFT_TYPE_OTHER,
            register: register(NFT_TYPE_OTHER, {
                validate: validateOther(NFT_TYPE, NFT_TYPE_OTHER, NFT_TYPE_ANSWERS)
            })
        },
        FEATURES_1: {
            field: FEATURES_1,
            answers: FEATURES_1_ANSWERS,
            substringToStyle: FEATURES_1_ANSWERS_STYLE,
            register: register(FEATURES_1)
        },
        FEATURES_1_OTHER: {
            field: FEATURES_1_OTHER,
            register: register(FEATURES_1_OTHER, {
                validate: validateOther(FEATURES_1, FEATURES_1_OTHER, FEATURES_1_ANSWERS)
            })
        },
        FEATURES_2: {
            field: FEATURES_2,
            answers: FEATURES_2_ANSWERS,
            substringToStyle: FEATURES_2_ANSWERS_STYLE,
            register: register(FEATURES_2, {
                validate: () => {
                    const features1Values = getValues(FEATURES_2);
                    if (features1Values.length < 1 || !features1Values) {
                        return ATLEAST_ONE;
                    }
                },
            })
        },
        FEATURES_2_OTHER: {
            field: FEATURES_2_OTHER,
            register: register(FEATURES_2_OTHER, {
                validate: validateOther(FEATURES_2, FEATURES_2_OTHER, FEATURES_2_ANSWERS)
            })
        },
        PROJECT_DESC: {
            field: PROJECT_DESC,
            register: register(PROJECT_DESC, {
                required: REQUIRED,
            })
        },
        NFT_PROJECT_BUDGET: {
            field: NFT_PROJECT_BUDGET,
            answers: NFT_PROJECT_BUDGET_ANSWERS,
            register: register(NFT_PROJECT_BUDGET)
        },
        TIME_TO_LAUNCH: {
            field: TIME_TO_LAUNCH,
            answers: TIME_TO_LAUNCH_ANSWERS,
            register: register(TIME_TO_LAUNCH, {
                required: REQUIRED,
            })
        },
        LENGTH_OF_SALE: {
            field: LENGTH_OF_SALE,
            answers: LENGTH_OF_SALE_ANSWERS,
            register: register(LENGTH_OF_SALE, {
                required: REQUIRED,
            })
        },
        ENGAGEMENT_LEVEL: {
            field: ENGAGEMENT_LEVEL,
            answers: ENGAGEMENT_LEVEL_ANSWERS,
            register: register(ENGAGEMENT_LEVEL)
        },
        NFT_SALE_EXPERIENCE: {
            field: NFT_SALE_EXPERIENCE,
            answers: NFT_SALE_EXPERIENCE_ANSWERS,
            register: register(NFT_SALE_EXPERIENCE, {
                required: REQUIRED,
            })
        },
        HEAR_SOURCE: {
            field: HEAR_SOURCE,
            answers: HEAR_SOURCE_ANSWERS,
            register: register(HEAR_SOURCE, {
                required: REQUIRED,
            }),
        },
        HEAR_SOURCE_OTHER: {
            field: HEAR_SOURCE_OTHER,
            register: register(HEAR_SOURCE_OTHER, {
                validate: validateOther(HEAR_SOURCE, HEAR_SOURCE_OTHER, HEAR_SOURCE_ANSWERS)
            })
        },
        FIRST_NAME: {
            field: FIRST_NAME, register: register(FIRST_NAME, {
                required: REQUIRED
            })
        },
        LAST_NAME: {
            field: LAST_NAME, register: register(LAST_NAME, {
                required: REQUIRED,
            })
        },
        COMPANY: {
            field: COMPANY,
            register: register(COMPANY, {
                required: REQUIRED,
            })
        },
        WEBSITE: { field: WEBSITE, register: register(WEBSITE) },
        PHONE: {
            field: PHONE,
            register: register(PHONE, {
                validate: () => {
                    const value = getValues(PHONE);
                    if (value) {
                        if (!isPossiblePhoneNumber(value)) {
                            return VALID_PHONE;
                        }
                    }
                },
            })
        },
        EMAIL: {
            field: EMAIL, register: register(EMAIL, {
                required: REQUIRED,
                pattern: {
                    value: /^\S+@\S+$/i,
                    message: VALID_EMAIL,
                },
            })
        }
    }
}